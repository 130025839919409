import React, { useState, useCallback } from 'react';
import displayWarningToast from '../utils/displayWarningToast';
import { useSignInMutation } from '../redux/services/auth';
import { useNavigate } from 'react-router-dom';
import { socket } from '../socket/socket';
import BulleForm from './BulleForm';

const LoginForm = () => {
  const [signIn, response] = useSignInMutation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setIsLoading(true);
      if (!email || !password) {
        displayWarningToast('Please fill in all fields', 'errorLoggingIn');
        setIsLoading(false);
        return;
      }
      const body = {
        email,
        password,
      };
      signIn({ body })
        .unwrap()
        .then((res: any) => {
          setIsLoading(false);
          socket.connect();
          socket.emit('join', res.user.id);
          navigate('/dashboard');
        })
        .catch((err: any) => {
          console.log(err);
          if (err.status === 400) {
            displayWarningToast(err.data.error, 'errorLoggingIn');
          }
          setIsLoading(false);
        });
    },
    [password, email]
  );

  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-around min-h-screen w-full">
      <div className="w-1/2" style={{ height: '65%' }}>
        <BulleForm
          title="Rappel sur les données personnelles"
          subtitle="Utilisation des données personnelles"
          text="Vos données sont sécrurisées et ne seront jamais partagées à des tiers. Vous pouvez consulter notre politique de confidentialité pour plus d'informations."
          buttonText="Politique de confidentialité"
          buttonLink="/privacy-policy"
        />
      </div>
      <div className="w-1/2 mr-auto max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8">
        <h5 className="text-xl font-medium text-gray-900">
          Connectez vous à Luter
        </h5>
        <form onSubmit={handleSubmit} className="bg-white px-8 pt-6 pb-8 mb-4">
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="email"
            >
              Email
            </label>
            <input
              required
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              type="email"
              placeholder="test@test.com"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            />
          </div>
          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="password"
            >
              Mot de passe
            </label>
            <input
              required
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              type="password"
              placeholder="*******"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            />
          </div>
          <div className="flex flex-col">
            <div className="flex items-center h-5">
              <input
                id="remember"
                type="checkbox"
                value=""
                className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
              />
              <label
                htmlFor="remember"
                className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Se souvenir de moi
              </label>
            </div>
            <a
              href="/reset-password/email-recovery"
              className="text-sm text-blue-700 hover:underline dark:text-blue-500 mt-2"
            >
              Mot de passe oublié ?
            </a>
          </div>
          <div className="flex items-center justify-center py-5">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
              type="submit"
            >
              {isLoading ? (
                <div className="flex justify-center">
                  <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-gray-900"></div>
                </div>
              ) : (
                'Connexion'
              )}
            </button>
          </div>
        </form>
        <div className="flex flex-col items-center mt-4">
          <h4>Pas encore de compte ?</h4>
          <a
            href="/register"
            className="text-sm text-blue-700 hover:underline dark:text-blue-500"
          >
            Créez en un en cliquant ici.
          </a>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
