import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useToggleSprintElementDoneMutation } from '../../redux/services/missions';
import { useGetMissionByIdQuery } from '../../redux/services/missions';
import { useToggleFreelanceApprovalSprintMutation } from '../../redux/services/missions';
import displayWarningToast from '../../utils/displayWarningToast';
import displaySuccessToast from '../../utils/displaySuccessToast';
import {
  BsEmojiAngry,
  BsList,
  BsCashCoin,
  BsCheckCircle,
  BsClock,
  BsCheck,
} from 'react-icons/bs';
import { AiOutlineCheck } from 'react-icons/ai';
import { useTransactionChargeMutation } from '../../redux/services/missions';
import { useEndMissionMutation } from '../../redux/services/missions';
import DashboardLayout from '../../Layout/DashboardLayout';
import Table from '../../Components/molecules/Table';
import MissionHeader from '../../Components/MissionHeader';
import { useGetDisputesQuery } from '../../redux/services/missions';
import Button from '../../Components/Button';

const MissionDetails = () => {
  const { id = '' } = useParams();
  const { data, refetch } = useGetMissionByIdQuery(id);
  const { data: disputes } = useGetDisputesQuery(parseInt(id as string), {
    refetchOnMountOrArgChange: true,
  });

  const project = (data as any)?.[0] as any;
  const [loading, setLoading] = useState(false);
  const [toggleFreelanceApproval] = useToggleFreelanceApprovalSprintMutation();
  const [transactionCharge] = useTransactionChargeMutation();
  const [toggleSprintElementDone] = useToggleSprintElementDoneMutation();
  const [endMission] = useEndMissionMutation();

  if (!project) {
    return null;
  }

  const onCheck = (e: any, sprintId: string, sprintElementId: string) => {
    setLoading(true);
    toggleSprintElementDone({
      sprintId: sprintId,
      sprintElementId: sprintElementId,
    })
      .unwrap()
      .then(() => {
        console.log(sprintId, sprintElementId);

        displaySuccessToast('Élément du sprint mis à jour !');
        setLoading(false);
        refetch();
      });
  };

  const onClick = async (sprintId: string) => {
    setLoading(true);
    await toggleFreelanceApproval({ sprintId })
      .unwrap()
      .then(() => {
        setLoading(false);
        displaySuccessToast('Sprint approuvé !');
      })
      .catch(() => {
        setLoading(false);
        displayWarningToast("Erreur lors de l'approbation du sprint !");
      });
  };

  if (!project) return null;

  const onClickEndMission = () => {
    setLoading(true);
    endMission({ id: project.id })
      .then(() => {
        displaySuccessToast(
          'Notification de finalisation de mission envoyée !'
        );
        setLoading(false);
        refetch();
      })
      .catch(() => {
        displayWarningToast('Erreur lors de la finalisation de la mission !');
        setLoading(false);
      });
  };

  const handleChargeTransaction = (sprintId: string) => {
    const sprint = project.sprints.find((s: any) => s.id === sprintId);
    const transaction = sprint.transactions.find(
      (t: any) => t.status === 'captured'
    );
    if (transaction) {
      transactionCharge({ id: transaction.id })
        .then(() => {
          alert('Payment captured successfully.');
          refetch();
        })
        .catch((error) => {
          console.error('Failed to capture payment:', error);
          alert('Failed to capture payment.');
        });
    } else {
      alert('No captured transaction available for this sprint.');
    }
  };

  const getSprintTransactionStatus = (sprintId: string) => {
    const sprint = project.sprints.find((s: any) => s.id === sprintId);
    const transaction = sprint.transactions.find(
      (t: any) => t.status === 'valid'
    );
    if (transaction) {
      return 'valid';
    }
    return 'captured';
  };

  const paymentMethods =
    project?.projectPayments &&
    project?.projectPayments.map((payment: any) => payment.name).join(', ');

  const findClientApprovedSprints = (sprintId?: string) => {
    const sprint = project.sprints.find((s: any) => s.id === sprintId);
    console.log(sprint);
    if (sprint) {
      return sprint.client_approval;
    }
    return false;
  };

  const generateTableData = (sprint: any) => {
    const isBankPrint = paymentMethods?.includes('bank_print');
    const isClientApproved = findClientApprovedSprints(sprint.id);

    return sprint.sprintElements?.map((element: any) => ({
      row: [
        element.description,
        element.quantity,
        `${element.price_ttc} €`,
        <input
          className="h-4 w-4"
          type="checkbox"
          disabled={
            sprint.client_approval || sprint.freelance_approval || loading
          }
          onChange={(e: any) => onCheck(e, sprint.id, element.id)}
          checked={element.done}
        />,
        getSprintTransactionStatus(sprint.id) === 'captured' ? (
          <Button
            size="small"
            onClick={() => handleChargeTransaction(sprint.id)}
            disabled={
              !isClientApproved ||
              !isBankPrint ||
              loading ||
              getSprintTransactionStatus(sprint.id) !== 'captured'
            }
          >
            <BsCashCoin className="mr-1" />
            Encaisser
          </Button>
        ) : (
          <p className="text-green-500">Encaissement effectué</p>
        ),
      ],
    }));
  };

  return (
    <DashboardLayout>
      <div className="px-4 py-5 sm:px-6 lg:px-8 ">
        <MissionHeader
          project={project}
          status={project?.status}
          payments={project?.projectPayments}
          sprints={project?.sprints}
          eventGoogleCalendar={{
            title: project?.name,
            location: 'Paris',
            description: 'Mission Luter',
            start: project?.start_date,
            end: project?.end_date,
          }}
          id={project?.id}
        />

        <hr className="bg-white w-full" />
        <div className="flex-col gap-4 sm:flex-row pt-5">
          <h2 className="text-mainBlack text-xl font-semibold">Sprints</h2>
          <div className="flex flex-wrap gap-1 md:gap-2 lg:gap-4 lg:ml-auto text-white mt-2">
            {!project?.sprints.some(
              (s: any) => !s.client_approval || !s.freelance_approval
            ) && (
              <Button
                disabled={
                  loading ||
                  project?.sprints.some(
                    (s: any) => !s.client_approval || !s.freelance_approval
                  )
                }
                onClick={onClickEndMission}
                primary
              >
                <AiOutlineCheck className="mr-1" />
                Finaliser la mission
              </Button>
            )}
            <Link to={`/mission/${id}/dispute/new`} state={project}>
              <Button>
                <BsEmojiAngry className="mr-1" />
                Déclarer un litige
              </Button>
            </Link>
            {(disputes as any)?.length > 0 && (
              <Link to={`/mission/${id}/disputes`} state={project}>
                <Button>
                  <BsList className="mr-1" />
                  Voir les litiges
                </Button>
              </Link>
            )}
          </div>
        </div>

        <div className="pr-5 pb-5 flex flex-col gap-4 sm:flex-row pt-5 ">
          <div className="flex w-full">
            <div className="w-full overflow-hidden px-2">
              {project?.sprints?.map((sprint: any) => (
                <div key={sprint.id} className="pb-4">
                  <div className="flex flex-col font-bold gap-4 text-2xl -mx-4 px-4 py-4 -mt-4 justify-between">
                    <div className="flex flex-col gap-4 sm:flex-row justify-between sm:items-center pt-2">
                      <h2 className="text-lg text-mainBlack font-normal sm:w-1/3">
                        Intitulé du sprint: {sprint.name}
                      </h2>
                      <div className="flex flex-col gap-3 text-lg sm:items-center font-normal">
                        <div className="flex gap-2">
                          <div className="flex flex-col gap-2">
                            {sprint.freelance_approval ? (
                              <div className="bg-green-200 text-sm px-2 py-1 rounded-xl font-light w-fit flex items-center justify-center gap-1">
                                <BsCheckCircle />
                                Validé par le freelance
                              </div>
                            ) : (
                              <div className="bg-yellow-200 text-sm px-2 py-1 rounded-xl font-light w-fit flex items-center justify-center gap-1">
                                <BsClock />
                                En attente de votre validation
                              </div>
                            )}
                          </div>
                          <div>
                            {sprint.client_approval ? (
                              <div className="bg-green-200 text-sm px-2 py-1 rounded-xl font-light w-fit flex items-center justify-center gap-1">
                                <BsCheckCircle />
                                Validé par le client
                              </div>
                            ) : (
                              <div className="bg-yellow-200 text-sm px-2 py-1 rounded-xl font-light w-fit flex items-center justify-center gap-1">
                                <BsClock />
                                En attente du client
                              </div>
                            )}
                          </div>
                        </div>{' '}
                        {sprint.freelance_approval === false &&
                          sprint.client_approval === true && (
                            <Button onClick={() => onClick(sprint.id)}>
                              <BsCheck className="mr-1" />
                              Approuver le sprint
                            </Button>
                          )}
                      </div>
                      <p className="font-normal text-mainBlack text-lg">
                        {sprint.price_ttc} €
                      </p>
                    </div>
                    <hr className="bg-white w-full" />
                    <Table
                      columns={[
                        'Description',
                        'Jours',
                        'Prix TTC',
                        'Terminé ?',
                        'Encaisser',
                      ]}
                      data={generateTableData(sprint)}
                      checkbox={false}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default MissionDetails;
