/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FormEvent, useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import DashboardLayout from '../../Layout/DashboardLayout';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import {
  useGetClientByIdQuery,
  useUpdateClientMutation,
} from '../../redux/services/clients';
import displayWarningToast from '../../utils/displayWarningToast';
import displaySuccessToast from '../../utils/displaySuccessToast';

const ClientDetails = () => {
  const user = useSelector((state: any) => state.user.user);
  const [updateClient] = useUpdateClientMutation();
  const navigate = useNavigate();
  const { id } = useParams();
  console.log(id);
  if (id === undefined) {
    return <div>404</div>;
  }
  const clientId = parseInt(id, 10);
  const {
    data: clientData,
    isLoading,
    isError,
  } = useGetClientByIdQuery<Record<string, any>>(clientId.toString());
  console.log(clientData);
  const [editableFirstName, setEditableFirstName] = useState(
    clientData?.first_name || ''
  );
  const [editableLastName, setEditableLastName] = useState(
    clientData?.last_name || ''
  );
  const [editableCompanyName, setEditableCompanyName] = useState(
    clientData?.company_name || ''
  );
  const [editableAddress, setEditableAddress] = useState(
    clientData?.address || ''
  );
  const [editablePhone, setEditablePhone] = useState(clientData?.phone || '');
  const [editableEmail, setEditableEmail] = useState(clientData?.email || '');
  const [editableSiret, setEditableSiret] = useState(clientData?.siret || '');
  const [editableTva, setEditableTva] = useState(clientData?.tva_number || '');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (clientData) {
      setEditableFirstName(clientData.first_name);
      setEditableLastName(clientData.last_name);
      setEditableCompanyName(clientData.company_name);
      setEditableAddress(clientData.address);
      setEditablePhone(clientData.phone);
      setEditableEmail(clientData.email);
      setEditableSiret(clientData.siret);
      setEditableTva(clientData.tva_number);
    }
  }, [clientData]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleDelete = () => {};

  const handleUpdate = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    const updatedClientDetails = {
      first_name: editableFirstName,
      last_name: editableLastName,
      company_name: editableCompanyName,
      address: editableAddress,
      phone: editablePhone,
      email: editableEmail,
      siret: editableSiret,
      tva_number: editableTva,
    };
    try {
      displayWarningToast('Mise à jour du client en cours', 'updatingClient');
      await updateClient({
        body: updatedClientDetails,
        id: clientId.toString(),
      });
      displaySuccessToast('Client mis à jour avec succès', 'updatingClient');
      navigate('/client/');
    } catch (error) {
      displayWarningToast(
        'Erreur lors de la mise à jour du client',
        'updatingClient'
      );
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <DashboardLayout>
      <form onSubmit={handleUpdate}>
        <div className="flex flex-col sm:flex-row justify-between items-center">
          <div className="text-4xl md:ml-8 text-mainBlack sm:ml-8 max-[640px]:text-center max-[640px]:my-4 max-[640px]:ml-8">
            Modifier les informations
          </div>
          <Link className="max-[640px]:ml-8" to="/client">
            <button className="flex bg-primary hover:bg-blue-600 text-white py-2 px-4 mb-4 mr-4 rounded ">
              <AiOutlineArrowLeft size={24} className="mr-2 text-white" />
              Retour
            </button>
          </Link>
        </div>
        {user.user_type == 'client' ? (
          <div className="flex justify-center items-center -mt-36 min-h-screen text-4xl text-primary">
            Vous n&apos;avez pas les droits pour accéder à cette page
          </div>
        ) : (
          <>
            <div className="h-2/3 bg-fullWhite mt-8 p-8 rounded-[30px] border border-whiteGrey shadow-xl overflow-auto md:ml-8 sm:ml-8 max-[640px]:ml-8 text-xl">
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div
                  style={{ display: 'flex' }}
                  className="justify-between mb-4"
                >
                  <div className="w-full mr-2">
                    <p className="text-primary w-full">
                      <strong>Prénom:</strong>
                    </p>
                    <input
                      type="text"
                      value={editableFirstName}
                      maxLength={14}
                      className="text-mainBlack w-full rounded px-2 border-2 border-lightGrey bg-fullWhite"
                      onChange={(e) => setEditableFirstName(e.target.value)}
                    />
                  </div>
                  <div className="w-full ml-2">
                    <p className="text-primary w-full">
                      <strong>Nom:</strong>
                    </p>
                    <input
                      type="text"
                      value={editableLastName}
                      maxLength={13}
                      className="text-mainBlack w-full rounded px-2 border-2 border-lightGrey bg-fullWhite"
                      onChange={(e) => setEditableLastName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="mb-4">
                  <p className="text-primary">
                    <strong>Nom de l&apos;entreprise:</strong>
                  </p>
                  <input
                    type="text"
                    value={editableCompanyName}
                    className="text-mainBlack w-full rounded px-2 border-2 border-lightGrey bg-fullWhite"
                    onChange={(e) => setEditableCompanyName(e.target.value)}
                  />
                </div>
                <div className="mb-4">
                  <p className="text-primary">
                    <strong>Adresse:</strong>
                  </p>
                  <input
                    type="text"
                    value={editableAddress}
                    className="text-mainBlack w-full rounded px-2 border-2 border-lightGrey bg-fullWhite"
                    onChange={(e) => setEditableAddress(e.target.value)}
                  />
                </div>
                <div className="mb-4">
                  <p className="text-primary w-full">
                    <strong>Téléphone:</strong>
                  </p>
                  <input
                    type="text"
                    value={editablePhone}
                    maxLength={13}
                    className="text-mainBlack w-full rounded px-2 border-2 border-lightGrey bg-fullWhite"
                    onChange={(e) => setEditablePhone(e.target.value)}
                  />
                </div>
                <div className="mb-4">
                  <p className="text-primary w-full">
                    <strong>Email:</strong>
                  </p>
                  <input
                    type="text"
                    value={editableEmail}
                    className="text-mainBlack w-full rounded px-2 border-2 border-lightGrey bg-fullWhite"
                    onChange={(e) => setEditableEmail(e.target.value)}
                  />
                </div>
                <div style={{ display: 'flex' }} className="justify-between">
                  <div className="w-full mr-2">
                    <p className="text-primary w-full">
                      <strong>SIRET:</strong>
                    </p>
                    <input
                      type="text"
                      value={editableSiret}
                      maxLength={14}
                      className="text-mainBlack w-full rounded px-2 border-2 border-lightGrey bg-fullWhite"
                      onChange={(e) => setEditableSiret(e.target.value)}
                    />
                  </div>
                  <div className="w-full ml-2">
                    <p className="text-primary w-full">
                      <strong>Numéro de TVA:</strong>
                    </p>
                    <input
                      type="text"
                      value={editableTva}
                      maxLength={13}
                      className="text-mainBlack w-full rounded px-2 border-2 border-lightGrey bg-fullWhite"
                      onChange={(e) => setEditableTva(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-around mt-8 ml-8">
              <button
                className="flex bg-red-500 hover:bg-red-600 text-white py-2 px-4 mt-4 rounded"
                onClick={handleDelete}
              >
                Supprimer
              </button>
              <button
                type="submit"
                className={`flex bg-primary hover:bg-blue-600 text-white py-2 px-4 mt-4 mr-4 rounded ${
                  isSubmitting && 'opacity-50 cursor-not-allowed'
                }`}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Mise à jour...' : 'Mettre à jour'}
              </button>
            </div>
          </>
        )}
      </form>
    </DashboardLayout>
  );
};

export default ClientDetails;
