import React, { useState } from 'react';
import GeneralInfo from '../GeneralInfo';
import PopUp from '../PopUp';
import DashboardLayout from '../../Layout/DashboardLayout';
import ProgressBar from '../ProgressBar';
import { Link } from 'react-router-dom';
import { TextPad } from './TextPad';
import { Blocks } from 'react-loader-spinner';

function MissionStep({
  data,
  setData,
  handleNext,
  handlePrevious,
  loading,
}: any) {
  const [subtitle, setSubtitle] = useState('Taux de commission');
  const [showPopup, setShowPopup] = useState<boolean>(false);
  console.log(data);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleConfirmPopup = () => {
    console.log('Popup confirmé!');
  };
  const handleCancelPopup = () => {
    console.log('Popup annulé!');
  };

  return (
    <div className="flex flex-col items-center justify-center w-full h-full overflow-hidden">
      {showPopup && (
        <PopUp
          title="Choisissez une option"
          text="Veuillez choisir une option avant de continuer."
          onConfirm={handleConfirmPopup}
          onCancel={handleCancelPopup}
          onClose={handleClosePopup}
          style={{ zIndex: 999 }}
        />
      )}
      <div
        className="p-2 w-full flex flex-row"
        style={{ height: '100%', padding: 30 }}
      >
        <div className="w-3/5 pr-4 relative flex flex-col">
          <ProgressBar percent="75" />
          <div className="flex flex-col gap-6" style={{ height: '100%' }}>
            <h1 className="text-2xl text-mainBlack font-bold mt-16">
              Détail de votre proposition
            </h1>
            <p className="text-mainBlack">
              Vous pouvez ajouter autant d&apos;éléments que vous le souhaitez.
              En plus d&apos;apporter de la clarté et de la transparence pour
              votre client, un devis détaillé vous permet de légitimer
              l&apos;ensemble de votre prestation en définissant un champ
              d&apos;action précis.
            </p>
            <TextPad data={data} setData={setData} />
          </div>
        </div>
        <div className="w-2/5 pl-4" style={{ height: '100%' }}>
          <GeneralInfo
            data={data}
            setData={setData}
            subtitle={subtitle}
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean tincidunt libero nec varius pellentesque. Suspendisse potenti. Donec acc"
          />
          <div className="mt-20 text-right flex items-center justify-between lg:mx-8 md:mx-4 sm:mx-2">
            <button
              onClick={handlePrevious}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition-all duration-300 transform hover:scale-110"
            >
              Retour
            </button>
            <button
              onClick={handleNext}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition-all duration-300 transform hover:scale-110
              disabled:opacity-50
              disabled:cursor-not-allowed
              disabled:bg-blue-500
              disabled:scale-100
              "
              disabled={
                data.formula === '' ||
                data.name === '' ||
                data.start_date === '' ||
                data.end_date === '' ||
                data.start_date >= data.end_date ||
                data.start_date < new Date().toISOString().split('T')[0] ||
                data.client_id === '' ||
                data.status === '' ||
                data.sprints.length < 0 ||
                data.sprints[0]?.price_ht === 0 ||
                loading
              }
            >
              {loading ? (
                <Blocks height="27" width="27" color="white" />
              ) : (
                <p>Créer la mission</p>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MissionStep;
