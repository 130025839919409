import React, { useState, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
// Icon
import { MdEdit, MdSave } from 'react-icons/md';

// Components
import SettingsMenu from '../../Components/Dashboard/SettingsMenu';
import Button from '../../Components/Button';
import DashboardLayout from '../../Layout/DashboardLayout';
import SettingsMenuSmall from '../../Components/Dashboard/SettingsMenuSmall';
import ToggleSwitcher from '../../Components/molecules/ToggleSwitcher';

const SettingsNotif: React.FC = () => {
  // useState
  const navigate = useNavigate();
  const [enabled, setEnabled] = useState(false);
  const [enabled2, setEnabled2] = useState(false);
  const [enabled3, setEnabled3] = useState(false);
  const [enabled4, setEnabled4] = useState(false);

  return (
    <DashboardLayout>
      <div className="flex">
        <div className="w-full p-4">
          <div className="flex flex-row mb-10">
            <h1 className="text-left text-4xl font-bold bold-4 w-1/2 text-left text-mainBlack">
              Paramètres du profil
            </h1>
            {/* <div className="w-1/2 text-right">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                onClick={() => navigate('/profile')}
              >
                Voir votre profil publique
              </button>
            </div> */}
          </div>
          <div className="md:hidden xs:block w-1/4 pr-4">
            <SettingsMenuSmall />
          </div>
          <div className="flex overflow-y-auto p-6 xs:-mr-8 md:mr-0">
            <div className="md:block xs:hidden w-1/4 pr-4">
              <SettingsMenu />
            </div>
            <div className="xs:hidden md:block w-px bg-gray-300 mx-4" />

            <div className="w-full">
              {/* Section 1 */}
              <div className="relative w-full overflow-y-auto border-2 rounded-[5px] p-4 mb-5">
                <div className="absolute top-0 left-0 w-full h-full bg-gray-300 bg-opacity-50 flex items-center justify-center rounded-[5px] z-50">
                  <span className="text-gray-700 font-bold text-2xl">
                    Bientôt disponible
                  </span>
                </div>
                <div className="flex items-center justify-between">
                  <h1 className="font-bold text-3xl mt-4">Messagerie</h1>
                </div>
                <h3 className="text-blue-500 ml-5">
                  Restez informé(e) des messages relatifs à vos missions grâce à
                  nos notifications.
                </h3>
                <hr className="my-2" />
                <div className="flex flex-row mt-10 mx-20">
                  <div className="flex flex-col">
                    <div className="items-center cursor-pointer mb-5">
                      <ToggleSwitcher
                        enabled={!enabled}
                        setEnabled={setEnabled}
                      />
                      <p className="flex -mt-7 ml-16 text-black font-bold">
                        Recevoir un SMS lorsqu&apos;on m&apos;envoie un message
                      </p>
                    </div>
                    <div className="items-center cursor-pointer mb-5">
                      <ToggleSwitcher
                        enabled={!enabled2}
                        setEnabled={setEnabled2}
                      />
                      <p className="flex -mt-7 ml-16 text-black font-bold">
                        Recevoir un e-mail lorsqu’on m’envoie un message
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Section 2 */}
              <div className="relative w-full overflow-y-auto border-2 rounded-[5px] p-4 mb-5">
                <div className="absolute top-0 left-0 w-full h-full bg-gray-300 bg-opacity-50 flex items-center justify-center rounded-[5px] z-50">
                  <span className="text-gray-700 font-bold text-2xl">
                    Bientôt disponible
                  </span>
                </div>
                <div className="flex items-center justify-between">
                  <h1 className="font-bold text-3xl mt-4">Activité</h1>
                </div>
                <h3 className="text-blue-500 ml-5">
                  Restez informé(e) des activités grâce à nos notifications.
                </h3>
                <hr className="my-2" />
                <div className="flex flex-row mt-10 mx-20">
                  <div className="flex flex-col">
                    <div className="items-center cursor-pointer mb-5">
                      <ToggleSwitcher
                        enabled={enabled3}
                        setEnabled={setEnabled3}
                      />
                      <p className="flex -mt-7 ml-16 text-black font-bold">
                        Recevoir des informations concernant les nouveautés de
                        Luter
                      </p>
                    </div>
                    <div className="items-center cursor-pointer mb-5">
                      <ToggleSwitcher
                        enabled={enabled4}
                        setEnabled={setEnabled4}
                      />
                      <p className="flex -mt-7 ml-16 text-black font-bold">
                        Recevoir un résumé de mes activités par email
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Section 3 */}
              <div className="relative w-full overflow-y-auto border-2 rounded-[5px] p-4 mb-5">
                <div className="flex items-center justify-between">
                  <h1 className="font-bold text-3xl mt-4 text-mainBlack">
                    Notifications obligatoires
                  </h1>
                </div>
                <h3 className="text-blue-500 ml-5">
                  Ces options ne peuvent pas être désactivées, elles sont
                  indispensables pour assurer le bon fonctionnement du service.
                </h3>
                <hr className="my-2" />
                <div className="flex flex-row mt-10 mx-20">
                  <div className="flex flex-col">
                    <div className="items-center cursor-pointer mb-5">
                      <ToggleSwitcher readonly={true} />
                      <p className="flex -mt-7 ml-16 text-black font-bold text-mainBlack">
                        Recevoir les e-mails concernant les modifications des
                        CGU
                      </p>
                    </div>
                    <div className="items-center cursor-pointer mb-5">
                      <ToggleSwitcher readonly={true} />
                      <p className="flex -mt-7 ml-16 text-black font-bold text-mainBlack">
                        Recevoir tout email important concernant votre compte
                        (sécurité, informations diverses...)
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default SettingsNotif;
