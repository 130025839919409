import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import ProgressBar from '../ProgressBar';
import BulleForm from '../BulleForm';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useSignUpFreelanceMutation } from '../../redux/services/auth';

const RegisterFormInfo2 = ({ onNext, onPrev, data, setData }: any) => {
  const [signUp] = useSignUpFreelanceMutation();
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const navigate = useNavigate();
  const [errors, setErrors] = useState<any>({});

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateForm()) {
      onNext();
    } else {
      toast.error('Veuillez corriger les erreurs dans le formulaire.');
    }
  };

  const businessTypes = [
    { value: 'company', label: 'Entreprise' },
    {
      value: 'government_entity',
      label: 'Entité gouvernementale (États-Unis uniquement)',
    },
    { value: 'individual', label: 'Individuel' },
  ];

  const itServices = [
    { value: '7372', label: 'Programmation informatique (7372)' },
    { value: '4816', label: 'Services de réseau informatique (4816)' },
    { value: '7379', label: "Réparation d'ordinateurs (7379)" },
    { value: '5734', label: 'Magasins de logiciels informatiques (5734)' },
    { value: '5045', label: 'Ordinateurs, périphériques et logiciels (5045)' },
    { value: '7375', label: "Services de recherche d'information (7375)" },
    // Création et design
    { value: '7336', label: 'Conception graphique (7336)' },
    { value: '7338', label: 'Photographie commerciale (7338)' },
    { value: '7812', label: 'Production de films et vidéos (7812)' },
    { value: '7333', label: 'Publicité et marketing (7333)' },

    // Services administratifs
    { value: '7331', label: 'Services de secrétariat (7331)' },
    { value: '8742', label: 'Conseil en gestion (8742)' },

    // Écriture et traduction
    { value: '8999', label: 'Écrivains et auteurs indépendants (8999)' },
    { value: '7389', label: 'Services de traduction (7389)' },

    // Enseignement et formation
    { value: '8299', label: 'Services de formation et coaching (8299)' },
    { value: '7999', label: 'Cours particuliers et éducation (7999)' },

    // Santé et bien-être
    { value: '8049', label: 'Services de coaching bien-être (8049)' },
    { value: '8041', label: 'Consultants en santé (8041)' },

    // Commerce et vente
    { value: '5969', label: 'Vente directe et e-commerce (5969)' },
    { value: '7311', label: 'Agents commerciaux indépendants (7311)' },

    // Construction et artisanat
    { value: '1761', label: 'Travaux de construction et rénovation (1761)' },
    { value: '1799', label: 'Travaux manuels et artisanat (1799)' },
  ];

  const handleBusinessTypeClick = (type: string) => {
    setData({ ...data, businessType: type });
  };

  const validateUrl = (url: string) => {
    const regex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})(\/[\w .-]*)*\/?$/;
    return regex.test(url);
  };

  const validateMcc = (mcc: string) => {
    return mcc.length > 0; // Validation simple, peut être ajustée
  };

  const validateAddress = (address: any) => {
    if (
      !data.companyAddress?.line1 ||
      !data.companyAddress?.city ||
      !data.companyAddress?.postal_code ||
      !data.companyAddress?.country
    ) {
      toast.warn('Adresse incomplète ou manquante.', {
        toastId: 'addressError',
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
    return (
      address.line1 && address.city && address.postal_code && address.country
    );
  };

  const validateDob = (dob: any) => {
    if (!dob) {
      toast.warn('Veuillez sélectionner une date de naissance.', {
        toastId: 'dobError',
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      return false;
    }

    const now = new Date();
    const dobDate = new Date(dob.year, dob.month - 1, dob.day);
    const diff = now.getTime() - dobDate.getTime();
    const ageDate = new Date(diff);
    const age = Math.abs(ageDate.getUTCFullYear() - 1970);

    if (age < 18) {
      toast.warn('Vous devez avoir plus de 18 ans.', {
        toastId: 'dobError',
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      return false;
    }

    return true;
  };

  const validateForm = () => {
    const newErrors: any = {};
    if (!validateUrl(data.url)) newErrors.url = 'URL invalide';
    if (!validateAddress(data.companyAddress))
      newErrors.companyAddress = 'Adresse incomplète';
    if (!validateDob(data.dob))
      newErrors.dob = 'Vous devez avoir plus de 18 ans';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (field: string, value: any) => {
    setData({ ...data, [field]: value });

    // Validate dynamically
    let newErrors = { ...errors };
    switch (field) {
      case 'url':
        if (!validateUrl(value)) newErrors.url = 'URL invalide';
        else delete newErrors.url;
        break;
      case 'companyAddress':
        if (!validateAddress(value))
          newErrors.companyAddress = 'Adresse incomplète';
        else delete newErrors.companyAddress;
        break;
      case 'dob':
        if (!validateDob(value))
          newErrors.dob = 'Vous devez avoir plus de 18 ans';
        else delete newErrors.dob;
        break;
      default:
        break;
    }
    setErrors(newErrors);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    if (!data.businessType) {
      setData({ ...data, businessType: 'individual' });
    }
  }, [data.businessType, setData]);

  return (
    <div className="flex flex-col md:flex-row min-h-screen overflow-hidden p-6 justify-center items-center pt-10">
      {windowWidth > 768 && (
        <div
          className="hidden md:flex w-full md:w-2/5  mb-10 md:mb-0 pt-10"
          style={{ height: '100%' }}
        >
          <BulleForm
            title="Aucun engagement !"
            subtitle="Utilisation des données personnelles"
            text="Vos données sont sécurisées et ne seront jamais partagées à des tiers. Vous pouvez consulter notre politique de confidentialité pour plus d'informations."
            buttonText="Politique de confidentialité"
            buttonLink="/privacy-policy"
          />
        </div>
      )}
      <div className="w-full md:w-4/5 flex flex-col justify-center items-center pt-10 md:mt-0">
        <ProgressBar percent={50} className="mb-10 w-full" />
        <div className="p-2 w-full flex flex-col justify-center items-center">
          <form
            onSubmit={handleSubmit}
            className="flex flex-col w-full md:w-2/3 rounded-md"
          >
            <div className="w-full mb-6 mt-6">
              <h2 className="text-gray-700 text-xl font-bold mb-4 text-center md:text-left">
                Type de Business
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {businessTypes.map((type) => {
                  const isDisabled =
                    data.businessType === 'individual' &&
                    type.value !== 'individual';
                  return (
                    <div
                      key={type.value}
                      className={`p-4 border rounded-lg text-center cursor-pointer ${
                        isDisabled
                          ? 'bg-gray-200 cursor-not-allowed'
                          : data.businessType === type.value
                          ? 'border-blue-500 bg-blue-50'
                          : 'border-gray-300'
                      }`}
                      onClick={() =>
                        !isDisabled && handleBusinessTypeClick(type.value)
                      }
                    >
                      {type.label}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="mb-4">
              <h2 className="text-gray-700 text-xl font-bold mb-4 text-center md:text-left">
                Profil de l'entreprise
              </h2>
              <div className="mb-4">
                <label
                  className="block text-gray-700 font-bold mb-2"
                  htmlFor="url"
                >
                  URL de l'entreprise
                </label>
                <input
                  type="text"
                  id="url"
                  value={data.url}
                  placeholder="https://www.votre-entreprise.com"
                  onChange={(e) => handleChange('url', e.target.value)}
                  className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                    errors.url ? 'border-red-500' : ''
                  }`}
                />
                {errors.url && (
                  <p className="text-red-500 text-xs italic">{errors.url}</p>
                )}
              </div>

              <div className="mb-4">
                <label
                  className="block text-gray-700 font-bold mb-2"
                  htmlFor="it_service"
                >
                  Secteur d'activité
                </label>
                <select
                  id="it_service"
                  value={data.mcc}
                  onChange={(e) => handleChange('mcc', e.target.value)}
                  className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                    errors.mcc ? 'border-red-500' : ''
                  }`}
                >
                  <option value="">Sélectionnez un service</option>
                  {itServices.map((service) => (
                    <option key={service.value} value={service.value}>
                      {service.label}
                    </option>
                  ))}
                </select>
                {errors.mcc && (
                  <p className="text-red-500 text-xs italic">{errors.mcc}</p>
                )}
              </div>

              <div className="mb-4">
                <label
                  className="block text-gray-700 font-bold mb-2"
                  htmlFor="companyAddress"
                >
                  Domiciliation de l'entreprise
                </label>
                <input
                  type="text"
                  id="line1"
                  value={data.companyAddress?.line1}
                  placeholder="Adresse"
                  onChange={(e) =>
                    handleChange('companyAddress', {
                      ...data.companyAddress,
                      line1: e.target.value,
                    })
                  }
                  className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2 ${
                    errors.companyAddress ? 'border-red-500' : ''
                  }`}
                />
                <input
                  type="text"
                  id="city"
                  value={data.companyAddress?.city}
                  placeholder="Ville"
                  onChange={(e) =>
                    handleChange('companyAddress', {
                      ...data.companyAddress,
                      city: e.target.value,
                    })
                  }
                  className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2 ${
                    errors.companyAddress ? 'border-red-500' : ''
                  }`}
                />
                <input
                  type="text"
                  id="postal_code"
                  value={data.companyAddress?.postal_code}
                  placeholder="Code postal"
                  onChange={(e) =>
                    handleChange('companyAddress', {
                      ...data.companyAddress,
                      postal_code: e.target.value,
                    })
                  }
                  className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2 ${
                    errors.companyAddress ? 'border-red-500' : ''
                  }`}
                />
                <input
                  type="text"
                  id="country"
                  value={data.companyAddress?.country}
                  placeholder="Pays"
                  onChange={(e) =>
                    handleChange('companyAddress', {
                      ...data.companyAddress,
                      country: e.target.value,
                    })
                  }
                  className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                    errors.companyAddress ? 'border-red-500' : ''
                  }`}
                />
                {errors.companyAddress && (
                  <p className="text-red-500 text-xs italic">
                    {errors.companyAddress}
                  </p>
                )}
              </div>

              <div className="mb-4">
                <label
                  className="block text-gray-700 font-bold mb-2"
                  htmlFor="dob"
                >
                  Date de naissance du gérant de l'entreprise
                </label>
                <DatePicker
                  selected={data.dob}
                  onChange={(date) => handleChange('dob', date)}
                  dateFormat="dd/MM/yyyy"
                  maxDate={new Date()} // Empêche la sélection de dates futures
                  placeholderText="Sélectionnez une date"
                  className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                    errors.dob ? 'border-red-500' : ''
                  }`}
                />
                {errors.dob && (
                  <p className="text-red-500 text-xs italic">{errors.dob}</p>
                )}
              </div>
            </div>

            <div className="flex items-center justify-between md:justify-between">
              <button
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-4"
                type="button"
                onClick={onPrev}
              >
                Précédent
              </button>
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
              >
                Suivant
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegisterFormInfo2;
